export const opaqueSet = new Set([
	'minecraft:acacia_planks',
	'minecraft:acacia_wood',
	'minecraft:amethyst_block',
	'minecraft:ancient_debris',
	'minecraft:andesite',
	'minecraft:barrel',
	'minecraft:bamboo_block',
	'minecraft:bamboo_mosaic',
	'minecraft:bamboo_planks',
	'minecraft:basalt',
	'minecraft:bedrock',
	'minecraft:bee_nest',
	'minecraft:beehive',
	'minecraft:birch_log',
	'minecraft:birch_planks',
	'minecraft:birch_wood',
	'minecraft:black_concrete',
	'minecraft:black_concrete_powder',
	'minecraft:black_glazed_terracotta',
	'minecraft:black_terracotta',
	'minecraft:blackstone',
	'minecraft:blast_furnace',
	'minecraft:blue_concrete',
	'minecraft:blue_concrete_powder',
	'minecraft:blue_glazed_terracotta',
	'minecraft:blue_ice',
	'minecraft:blue_terracotta',
	'minecraft:blue_wool',
	'minecraft:bone_block',
	'minecraft:bookshelf',
	'minecraft:brain_coral_block',
	'minecraft:bricks',
	'minecraft:brown_concrete',
	'minecraft:brown_concrete_powder',
	'minecraft:brown_glazed_terracotta',
	'minecraft:brown_mushroom_block',
	'minecraft:brown_terracotta',
	'minecraft:brown_wool',
	'minecraft:bubble_coral_block',
	'minecraft:calcite',
	'minecraft:cartography_table',
	'minecraft:carved_pumpkin',
	'minecraft:chain_command_block',
	'minecraft:cherry_log',
	'minecraft:cherry_planks',
	'minecraft:cherry_wood',
	'minecraft:chiseled_bookshelf',
	'minecraft:chiseled_deepslate',
	'minecraft:chiseled_nether_bricks',
	'minecraft:chiseled_polished_blackstone',
	'minecraft:chiseled_quartz_block',
	'minecraft:chiseled_red_sandstone',
	'minecraft:chiseled_sandstone',
	'minecraft:chiseled_stone_bricks',
	'minecraft:clay',
	'minecraft:coal_block',
	'minecraft:coal_ore',
	'minecraft:coarse_dirt',
	'minecraft:cobbled_deepslate',
	'minecraft:cobbled_deepslate_wall',
	'minecraft:cobblestone',
	'minecraft:command_block',
	'minecraft:copper_block',
	'minecraft:copper_ore',
	'minecraft:cracked_deepslate_bricks',
	'minecraft:cracked_deepslate_tiles',
	'minecraft:cracked_nether_bricks',
	'minecraft:cracked_polished_blackstone_bricks',
	'minecraft:cracked_stone_bricks',
	'minecraft:crafting_table',
	'minecraft:crimson_hyphae',
	'minecraft:crimson_nylium',
	'minecraft:crimson_planks',
	'minecraft:crimson_roots',
	'minecraft:crimson_stem',
	'minecraft:crying_obsidian',
	'minecraft:cut_copper',
	'minecraft:cut_red_sandstone',
	'minecraft:cut_sandstone',
	'minecraft:cyan_concrete',
	'minecraft:cyan_concrete_powder',
	'minecraft:cyan_glazed_terracotta',
	'minecraft:cyan_terracotta',
	'minecraft:cyan_wool',
	'minecraft:dark_oak_log',
	'minecraft:dark_oak_planks',
	'minecraft:dark_oak_wood',
	'minecraft:dark_prismarine',
	'minecraft:dead_brain_coral_block',
	'minecraft:dead_bubble_coral_block',
	'minecraft:dead_fire_coral_block',
	'minecraft:dead_horn_coral_block',
	'minecraft:dead_tube_coral_block',
	'minecraft:deepslate',
	'minecraft:deepslate_bricks',
	'minecraft:deepslate_coal_ore',
	'minecraft:deepslate_copper_ore',
	'minecraft:deepslate_diamond_ore',
	'minecraft:deepslate_emerald_ore',
	'minecraft:deepslate_gold_ore',
	'minecraft:deepslate_iron_ore',
	'minecraft:deepslate_lapis_ore',
	'minecraft:deepslate_redstone_ore',
	'minecraft:deepslate_tiles',
	'minecraft:diamond_block',
	'minecraft:diamond_ore',
	'minecraft:diorite',
	'minecraft:dirt',
	'minecraft:dispenser',
	'minecraft:dried_kelp_block',
	'minecraft:dripstone_block',
	'minecraft:dropper',
	'minecraft:emerald_block',
	'minecraft:emerald_ore',
	'minecraft:end_stone',
	'minecraft:end_stone_bricks',
	'minecraft:exposed_copper',
	'minecraft:exposed_cut_copper',
	'minecraft:fire_coral_block',
	'minecraft:fletching_table',
	'minecraft:furnace',
	'minecraft:gilded_blackstone',
	'minecraft:glowstone',
	'minecraft:gold_block',
	'minecraft:gold_ore',
	'minecraft:granite',
	'minecraft:grass_block',
	'minecraft:gravel',
	'minecraft:gray_concrete',
	'minecraft:gray_concrete_powder',
	'minecraft:gray_glazed_terracotta',
	'minecraft:gray_terracotta',
	'minecraft:gray_wool',
	'minecraft:green_concrete',
	'minecraft:green_concrete_powder',
	'minecraft:green_glazed_terracotta',
	'minecraft:green_terracotta',
	'minecraft:green_wool',
	'minecraft:hay_block',
	'minecraft:honeycomb_block',
	'minecraft:horn_coral_block',
	'minecraft:infested_chiseled_stone_bricks',
	'minecraft:infested_cobblestone',
	'minecraft:infested_cracked_stone_bricks',
	'minecraft:infested_deepslate',
	'minecraft:infested_mossy_stone_bricks',
	'minecraft:infested_stone',
	'minecraft:infested_stone_bricks',
	'minecraft:iron_block',
	'minecraft:iron_ore',
	'minecraft:jack_o_lantern',
	'minecraft:jigsaw',
	'minecraft:jukebox',
	'minecraft:jungle_log',
	'minecraft:jungle_planks',
	'minecraft:jungle_wood',
	'minecraft:lapis_block',
	'minecraft:lapis_ore',
	'minecraft:light_blue_concrete',
	'minecraft:light_blue_concrete_powder',
	'minecraft:light_blue_glazed_terracotta',
	'minecraft:light_blue_terracotta',
	'minecraft:light_blue_wool',
	'minecraft:light_gray_concrete',
	'minecraft:light_gray_concrete_powder',
	'minecraft:light_gray_glazed_terracotta',
	'minecraft:light_gray_terracotta',
	'minecraft:light_gray_wool',
	'minecraft:lime_concrete',
	'minecraft:lime_concrete_powder',
	'minecraft:lime_glazed_terracotta',
	'minecraft:lime_terracotta',
	'minecraft:lime_wool',
	'minecraft:lodestone',
	'minecraft:loom',
	'minecraft:magenta_concrete',
	'minecraft:magenta_concrete_powder',
	'minecraft:magenta_glazed_terracotta',
	'minecraft:magenta_terracotta',
	'minecraft:magenta_wool',
	'minecraft:magma_block',
	'minecraft:mangrove_log',
	'minecraft:mangrove_planks',
	'minecraft:mangrove_wood',
	'minecraft:melon',
	'minecraft:moss_block',
	'minecraft:mossy_cobblestone',
	'minecraft:mossy_stone_bricks',
	'minecraft:mud',
	'minecraft:mud_bricks',
	'minecraft:mycelium',
	'minecraft:nether_bricks',
	'minecraft:nether_gold_ore',
	'minecraft:nether_quartz_ore',
	'minecraft:nether_wart_block',
	'minecraft:netherite_block',
	'minecraft:netherrack',
	'minecraft:note_block',
	'minecraft:oak_log',
	'minecraft:oak_planks',
	'minecraft:oak_wood',
	'minecraft:observer',
	'minecraft:obsidian',
	'minecraft:ochre_froglight',
	'minecraft:orange_concrete',
	'minecraft:orange_concrete_powder',
	'minecraft:orange_glazed_terracotta',
	'minecraft:orange_terracotta',
	'minecraft:orange_wool',
	'minecraft:oxidized_copper',
	'minecraft:oxidized_cut_copper',
	'minecraft:packed_ice',
	'minecraft:packed_mud',
	'minecraft:pearlescent_froglight',
	'minecraft:pink_concrete',
	'minecraft:pink_concrete_powder',
	'minecraft:pink_glazed_terracotta',
	'minecraft:pink_terracotta',
	'minecraft:pink_wool',
	'minecraft:podzol',
	'minecraft:polished_andesite',
	'minecraft:polished_basalt',
	'minecraft:polished_blackstone',
	'minecraft:polished_blackstone_bricks',
	'minecraft:polished_deepslate',
	'minecraft:polished_diorite',
	'minecraft:polished_granite',
	'minecraft:powder_snow',
	'minecraft:prismarine',
	'minecraft:prismarine_bricks',
	'minecraft:pumpkin',
	'minecraft:purple_concrete',
	'minecraft:purple_concrete_powder',
	'minecraft:purple_glazed_terracotta',
	'minecraft:purple_terracotta',
	'minecraft:purple_wool',
	'minecraft:purpur_block',
	'minecraft:purpur_pillar',
	'minecraft:quartz_block',
	'minecraft:quartz_bricks',
	'minecraft:quartz_pillar',
	'minecraft:raw_copper_block',
	'minecraft:raw_gold_block',
	'minecraft:raw_iron_block',
	'minecraft:red_concrete',
	'minecraft:red_concrete_powder',
	'minecraft:red_glazed_terracotta',
	'minecraft:red_mushroom_block',
	'minecraft:red_nether_bricks',
	'minecraft:red_sand',
	'minecraft:red_sandstone',
	'minecraft:red_terracotta',
	'minecraft:red_wool',
	'minecraft:redstone_block',
	'minecraft:redstone_lamp',
	'minecraft:redstone_ore',
	'minecraft:repeating_command_block',
	'minecraft:respawn_anchor',
	'minecraft:rooted_dirt',
	'minecraft:sand',
	'minecraft:sandstone',
	'minecraft:sculk',
	'minecraft:sculk_catalyst',
	'minecraft:sea_lantern',
	'minecraft:shroomlight',
	'minecraft:smithing_table',
	'minecraft:smoker',
	'minecraft:smooth_basalt',
	'minecraft:smooth_quartz',
	'minecraft:smooth_red_sandstone',
	'minecraft:smooth_sandstone',
	'minecraft:smooth_stone',
	'minecraft:snow_block',
	'minecraft:soul_sand',
	'minecraft:soul_soil',
	'minecraft:sponge',
	'minecraft:spruce_log',
	'minecraft:spruce_planks',
	'minecraft:spruce_wood',
	'minecraft:stone',
	'minecraft:stone_bricks',
	'minecraft:stripped_acacia_log',
	'minecraft:stripped_acacia_wood',
	'minecraft:stripped_bamboo_block',
	'minecraft:stripped_birch_log',
	'minecraft:stripped_birch_wood',
	'minecraft:stripped_cherry_log',
	'minecraft:stripped_cherry_wood',
	'minecraft:stripped_crimson_hyphae',
	'minecraft:stripped_crimson_stem',
	'minecraft:stripped_dark_oak_log',
	'minecraft:stripped_dark_oak_wood',
	'minecraft:stripped_jungle_log',
	'minecraft:stripped_jungle_wood',
	'minecraft:stripped_mangrove_log',
	'minecraft:stripped_mangrove_wood',
	'minecraft:stripped_oak_log',
	'minecraft:stripped_oak_wood',
	'minecraft:stripped_spruce_log',
	'minecraft:stripped_spruce_wood',
	'minecraft:stripped_warped_hyphae',
	'minecraft:stripped_warped_stem',
	'minecraft:structure_block',
	'minecraft:suspicious_gravel',
	'minecraft:suspicious_sand',
	'minecraft:target',
	'minecraft:terracotta',
	'minecraft:tnt',
	'minecraft:tube_coral_block',
	'minecraft:tuff',
	'minecraft:verdant_froglight',
	'minecraft:warped_hyphae',
	'minecraft:warped_nylium',
	'minecraft:warped_planks',
	'minecraft:warped_stem',
	'minecraft:warped_wart_block',
	'minecraft:waxed_copper_block',
	'minecraft:waxed_cut_copper',
	'minecraft:waxed_exposed_copper',
	'minecraft:waxed_exposed_cut_copper',
	'minecraft:waxed_oxidized_copper',
	'minecraft:waxed_oxidized_cut_copper',
	'minecraft:waxed_weathered_copper',
	'minecraft:waxed_weathered_cut_copper',
	'minecraft:weathered_copper',
	'minecraft:weathered_cut_copper',
	'minecraft:wet_sponge',
	'minecraft:white_concrete',
	'minecraft:white_concrete_powder',
	'minecraft:white_glazed_terracotta',
	'minecraft:white_terracotta',
	'minecraft:white_wool',
	'minecraft:yellow_concrete',
	'minecraft:yellow_concrete_powder',
	'minecraft:yellow_glazed_terracotta',
	'minecraft:yellow_terracotta',
	'minecraft:yellow_wool',
	"minecraft:crafter",
	"minecraft:chiseled_tuff",
	"minecraft:chiseled_tuff_bricks",
	"minecraft:polished_tuff",
	"minecraft:tuff_bricks",
	"minecraft:chiseled_copper",
	"minecraft:weathered_chiseled_copper",
	"minecraft:exposed_chiseled_copper",
	"minecraft:oxidized_chiseled_copper",
	"minecraft:copper_bulb",
	"minecraft:weathered_copper_bulb",
	"minecraft:exposed_copper_bulb",
	"minecraft:oxidized_copper_bulb",
	"minecraft:waxed_copper_bulb",
	"minecraft:waxed_weathered_copper_bulb",
	"minecraft:waxed_exposed_copper_bulb",
	"minecraft:waxed_oxidized_copper_bulb",
	"minecraft:waxed_chiseled_copper",
	"minecraft:waxed_exposed_chiseled_copper",
	"minecraft:waxed_oxidized_chiseled_copper",
	"minecraft:waxed_weathered_chiseled_copper",
])

export const transparentSet = new Set([
	"minecraft:water",
	"minecraft:flowing_water",
	"minecraft:white_stained_glass",
	"minecraft:orange_stained_glass",
	"minecraft:magenta_stained_glass",
	"minecraft:light_blue_stained_glass",
	"minecraft:yellow_stained_glass",
	"minecraft:lime_stained_glass",
	"minecraft:pink_stained_glass",
	"minecraft:gray_stained_glass",
	"minecraft:light_gray_stained_glass",
	"minecraft:cyan_stained_glass",
	"minecraft:purple_stained_glass",
	"minecraft:blue_stained_glass",
	"minecraft:brown_stained_glass",
	"minecraft:green_stained_glass",
	"minecraft:red_stained_glass",
	"minecraft:black_stained_glass",
	"minecraft:white_stained_glass_pane",
	"minecraft:orange_stained_glass_pane",
	"minecraft:magenta_stained_glass_pane",
	"minecraft:light_blue_stained_glass_pane",
	"minecraft:yellow_stained_glass_pane",
	"minecraft:lime_stained_glass_pane",
	"minecraft:pink_stained_glass_pane",
	"minecraft:gray_stained_glass_pane",
	"minecraft:light_gray_stained_glass_pane",
	"minecraft:cyan_stained_glass_pane",
	"minecraft:purple_stained_glass_pane",
	"minecraft:blue_stained_glass_pane",
	"minecraft:brown_stained_glass_pane",
	"minecraft:green_stained_glass_pane",
	"minecraft:red_stained_glass_pane",
	"minecraft:black_stained_glass_pane",
	"minecraft:tinted_glass",
	"minecraft:copper_grate",
	"minecraft:exposed_copper_grate",
	"minecraft:weathered_copper_grate",
	"minecraft:oxidized_copper_grate",
	"minecraft:waxed_copper_grate",
	"minecraft:waxed_exposed_copper_grate",
	"minecraft:waxed_weathered_copper_grate",
	"minecraft:waxed_oxidized_copper_grate",
])

export const selfCullingSet = new Set([
	"minecraft:dirt_path",
	"minecraft:farmland",
	"minecraft:tinted_glass",
	"minecraft:glass",
	"minecraft:white_stained_glass",
	"minecraft:orange_stained_glass",
	"minecraft:magenta_stained_glass",
	"minecraft:light_blue_stained_glass",
	"minecraft:yellow_stained_glass",
	"minecraft:lime_stained_glass",
	"minecraft:pink_stained_glass",
	"minecraft:gray_stained_glass",
	"minecraft:light_gray_stained_glass",
	"minecraft:cyan_stained_glass",
	"minecraft:purple_stained_glass",
	"minecraft:blue_stained_glass",
	"minecraft:brown_stained_glass",
	"minecraft:green_stained_glass",
	"minecraft:red_stained_glass",
	"minecraft:black_stained_glass",
	"minecraft:copper_grate",
	"minecraft:exposed_copper_grate",
	"minecraft:weathered_copper_grate",
	"minecraft:oxidized_copper_grate",
	"minecraft:waxed_copper_grate",
	"minecraft:waxed_exposed_copper_grate",
	"minecraft:waxed_weathered_copper_grate",
	"minecraft:waxed_oxidized_copper_grate",
])